@import url("https://fonts.googleapis.com/css?family=Bree+Serif|Roboto:300,400&display=swap");
@import url("https://fonts.googleapis.com/css?family=Patua+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none;
  border: none;
  border-color: var(--main-color);
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#root {
  height: 100%;
  /* --main-color: #00a76f; */
  --main-color: #00a76f;
  --transparency-main-color: rgba(0, 167, 111, 0.1);
  --highlight-color: #fafafa;
  --title-color: #3f3d56;
  --empty-color: rgba(192, 192, 192);
  --deny-color: #b91646;
  --transparency-deny-color: rgba(185, 22, 70, 0.1);
  --bg-color: #FAFAFC;
  /* --bg-color: #FAFAFC; */
  background-color: var(--bg-color);
}

.skeleton {
  margin-top: 10px;
  background-color: var(--transparency-main-color) !important;
  border-radius: 20px;
}

a {
  color: var(--main-color);
}

input::placeholder {
  opacity: 0.5;
}

.input {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;
}

.input::placeholder {
  color: var(--main-color);
  opacity: 0.7;
}

.input:disabled {
  color: var(--main-color);
  border-bottom: 1px solid rgba(255, 255, 255, 0.7) !important;
}

.input:focus {
  color: var(--main-color);
  border-bottom: 2px solid rgba(255, 255, 255, 0.7) !important;
}

.input:not(:placeholder-shown) {
  color: var(--main-color);
  border-bottom: 2px solid rgba(255, 255, 255, 0.7) !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.img {
  width: 85%;
  height: 70%;
}

.margin-l {
  margin-left: 20px !important;
}

.chip {
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.success {
  background-color: var(--transparency-main-color);
  color: var(--main-color);
  font-weight: bold;
}

.grey {
  background-color: #142f43;
  color: #e6e6e6;
  font-weight: bold;
}

.blue {
  background-color: #344cb7;
  color: #e6e6e6;
  font-weight: bold;
}

.waiting {
  background-color: #eed6c4;
  color: #6b4f4f;
  font-weight: bold;
}

.red {
  background-color: #ff5c58;
  color: #ffedd3;
  font-weight: bold;
}
